/*
 * Licensed under the EUPL, Version 1.2 or – as soon they will be approved by
 * the European Commission - subsequent versions of the EUPL (the "Licence");
 * You may not use this work except in compliance with the Licence.
 * You may obtain a copy of the Licence at:
 *
 *   https://joinup.ec.europa.eu/software/page/eupl
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the Licence is distributed on an "AS IS" basis,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the Licence for the specific language governing permissions and
 * limitations under the Licence.
 *
 */

/* Async Actions */
export const REQUESTED_SUPPLIERS = "REQUESTED_SUPPLIERS";
export const RECEIVED_SUPPLIERS = "RECEIVED_SUPPLIERS";
export const ERROR_SUPPLIERS = "ERROR_SUPPLIERS";

/* User actions */
export const CHANGED_ACTIVE_PROVIDER = "CHANGED_ACTIVE_PROVIDER";
export const USER_NO_ORGANISATIONS = "USER_NO_ORGANISATIONS";
